@font-face {
  font-family: 'OCR-ABTRegular';
  src: local('OCR-ABTRegular'), url(../src/fonts/OCR-ABTRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'OCRAStd';
  src: local('OCRAStd'), url(./fonts/OCRAStd.otf) format('opentype');
}
.App{
  font-family: 'OCR-ABTRegular';
}

::-webkit-input-placeholder { /* Edge */
  color: #c1c1c1 !important;
}